import React, { useEffect, useState } from "react";

import PropelldLogo from "./PropelldLogo";
import DesktopBackground from "../../assets/background_desktop.svg";
import MobileBackground from "../../assets/background_mob.svg";
import NewBg from "../../assets/NewBg.png";
import CustomerForm from "./CustomerForm";
import PWImg from "../../assets/PW-removebg-preview.png";
import AllenLogo from "../../assets/Allen_logo.png";
import AakashLogoColor from "assets/AakashLogo.svg";
import UnacademyLogo from "assets/UnacademyLogo.png";
import MotionLogo from "assets/MotionLogo.png";
import ArrowRight from "assets/ArrowRight.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MITSDE from "assets/MITSDE.jpg";

import ResonanceLogo from "../../assets/Resonance_Logo.png";

import { Button } from "@mui/material";
import "./style.scss";
import { getApprovedAmount, institutesList, stepsData } from "utils/constants";
import { RootState } from "config/store";
import { ConnectedProps, connect } from "react-redux";
import { EligibilityCodes } from "./CustomerForm/helper";
import { useParams } from "react-router-dom";

const logosByInstitute = {
	ALLEN: AllenLogo,
	AAKASH: AakashLogoColor,
	MOTION: MotionLogo,
	PHYSICSWALLAH: PWImg,
	RESONANCE: ResonanceLogo,
	UNACADEMY: UnacademyLogo,
	MITSDE: MITSDE,
};

const mapStateToProps = (state: RootState) => 
{
	const { ui, customer } = state;
	return {
		ui: ui,
		generatedUserRequest: customer.generatedUserRequest,
		eligibilityResult: customer.eligibilityResult,
	};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

const HomePage: React.FC<ReduxProps> = (props) => 
{
	const { instituteId } = useParams();
	const defaultInstitute =
        instituteId &&
        institutesList[
            instituteId.toLocaleUpperCase() as keyof typeof institutesList
        ]
        	? instituteId.toLocaleUpperCase()
        	: "";

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [selectedInstitute, setSelectedInstitute] = useState(defaultInstitute);

	useEffect(() => 
	{
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [selectedInstitute]);

	useEffect(() => 
	{
		// Function to update windowWidth when the window is resized
		const handleResize = () => 
		{
			setWindowWidth(window.innerWidth);
		};

		// Attach the event listener when the component mounts
		window.addEventListener("resize", handleResize);

		// Remove the event listener when the component unmounts
		return () => 
		{
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	if (
		props?.eligibilityResult &&
        props.eligibilityResult?.EligibilityCode === EligibilityCodes.REJECTED
	) 
	{
		return (
			<div className="failed">
				<div className="header__logos">
					<PropelldLogo />
				</div>
				<div className="failed_content">
					<div className="failed_title">
						<p>Thank You For</p>
						<p>Applying Coaching Loan With Propelld</p>
					</div>
					<div className="failed_subtitle">
						<p>We&apos;d Get Back To You Shortly!</p>
					</div>
					<Button
						onClick={() => window.open("tel:+918970707081")}
						variant="contained"
						color="inherit"
						sx={{
							backgroundColor: "#ffffff",
							padding: "10xp 40px",
							fontWeight: 700,
							marginTop: "20px",
							zIndex: 2,
						}}
					>
                        Call Us Now
					</Button>
				</div>
				<div>
					<img
						className="failed_image"
						src={NewBg}
						alt="DesktopBackground"
					/>
				</div>
			</div>
		);
	}

	if (props?.eligibilityResult && selectedInstitute) 
	{
		return (
			<div className="steps">
				{!defaultInstitute && (
					<ArrowBackIcon
						className="back_icon"
						onClick={() => setSelectedInstitute("")}
					/>
				)}
				<PropelldLogo className="propelld__logo" />
				<div className="left_container">
					<div className="left_content">
						<img
							src={
								logosByInstitute[
                                    selectedInstitute as keyof typeof logosByInstitute
								]
							}
							className="steps_logo"
						/>
						<p className="steps_title">{selectedInstitute}</p>
						<p className="steps_subtitle">
                            Follow The Next Steps To Get A Loan
						</p>
						<img src={ArrowRight} className="arrow_sign" />
						{props?.eligibilityResult?.PolicyName && (
							<div className="steps_desc">
								<p className="desc_title">Approved Amount</p>
								<p className="amount">
                                    ₹{" "}
									{getApprovedAmount(
										selectedInstitute,
										props?.eligibilityResult?.PolicyName ||
                                            "",
									)}
								</p>
							</div>
						)}
					</div>
				</div>
				<div className="right_container">
					<div className="steps_section">
						{selectedInstitute &&
                            stepsData[
                                selectedInstitute as keyof typeof stepsData
                            ]?.map((ele, index) => (
                            	<div key={index} className="step">
                            		<p className="count">STEP {index + 1}</p>
                            		<p className="details">{ele}</p>
                            	</div>
                            ))}
						<div className="contact_section">
							<p>Fore more information</p>
							<Button
								onClick={() => window.open("tel:+918970707081")}
								variant="contained"
								color="primary"
							>
                                Call Us Now
							</Button>
						</div>
						<p></p>
					</div>
				</div>
			</div>
		);
	}

	if (props?.eligibilityResult) 
	{
		return (
			<div className="success">
				<div className="content">
					<div className="header__logos">
						<PropelldLogo />
					</div>
					<div className="success_content">
						<div className="success_title">
							<p>Hurray!</p>
						</div>
						<div className="success_subtitle">
							<p>You Are Approved For NO COST EMI</p>
						</div>
						<div className="success_desc">
							<p className="desc_title">
                                At the following For Next Institutes
							</p>
							<p className="desc_subtitle">
                                Choose You Institute For Next Steps
							</p>
						</div>
					</div>
				</div>
				<div className="institutes">
					{[
						{
							key: institutesList.ALLEN,
							title: "Allen",
							logo: AllenLogo,
						},
						{
							key: institutesList.AAKASH,
							title: "Aakash",
							logo: AakashLogoColor,
						},
						{
							key: institutesList.PHYSICSWALLAH,
							title: "Physicswallah",
							logo: PWImg,
						},
						{
							key: institutesList.UNACADEMY,
							title: "Unacademy",
							logo: UnacademyLogo,
						},
						{
							key: institutesList.RESONANCE,
							title: "Resonance",
							logo: ResonanceLogo,
						},
						{
							key: institutesList.MOTION,
							title: "Motion",
							logo: MotionLogo,
						},
					].map((ele) => (
						<Button
							key={ele.key}
							onClick={() => setSelectedInstitute(ele.key)}
							variant="contained"
							color="inherit"
							sx={{
								backgroundColor: "#ffffff",
								padding: "10xp 40px",
								fontWeight: 700,
								zIndex: 2,
								margin: "-50px 10px 70px",
							}}
						>
							<div className="insti_button">
								<div className="insti_logo_section">
									<img
										className="insti_logo"
										src={ele.logo}
									/>
								</div>
								<p className="insti_title">{ele.title}</p>
								{props.eligibilityResult?.PolicyName && (
									<>
										<p className="insti_subtitle">
                                            Approved Amount
										</p>
										<p className="insti_amount">
                                            ₹{" "}
											{getApprovedAmount(
												ele.key,
												props.eligibilityResult
													.PolicyName,
											)}
										</p>
									</>
								)}
							</div>
						</Button>
					))}
				</div>
			</div>
		);
	}

	return (
		<div className="main">
			<div className="header__logos">
				<PropelldLogo />
			</div>
			<div className="background__img">
				<img
					src={
						windowWidth > 700 ? DesktopBackground : MobileBackground
					}
					alt="DesktopBackground"
					style={{ display: "block", maxHeight: "100%" }}
					height={"50%"}
					width={"100%"}
				/>
			</div>

			<>
				<CustomerForm />
			</>
		</div>
	);
};

export default connector(HomePage);

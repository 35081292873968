import { NetworkRequest } from "../models/NetworkRequest";
import NetworkRequestHelper from "../helper";
import baseUrl from "../../config/service";
// import { GetPincodeDetailsRequest } from "./models/GetPincodeDetailsRequest";
import { GenerateUserConsentOTPRequest } from "./models/GenerateUserConsent";
import { VerifyUserConsentOTPRequest } from "./models/VerifyUserConsent";
import { ResendUserConsentOTPRequest } from "./models/ResendUserConsentOTP";
import { TriggerPabblyWebhooRequest } from "./models/TriggerPabblyWebhoo";

class CustomerService 
{
	// static getPincodeDetailsForApplication = (
	// 	data: GetPincodeDetailsRequest,
	// ): NetworkRequest =>
	// {
	// 	return NetworkRequestHelper.GET(
	// 		baseUrl.staticdata + "/pincode/search",
	// 		data,
	// 	);
	// };

	static generateUserConsentOTP = (
		data: GenerateUserConsentOTPRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.customer + "/details/add/checkyourloan",
			data,
		);
	};

	static verifyUserConsentOTP = (
		data: VerifyUserConsentOTPRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.customer + "/consent/verify-otp-and-eval-credit",
			data,
		);
	};

	static resendUserConsentOTP = (
		data: ResendUserConsentOTPRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.GET(
			baseUrl.customer + "/consent/resend-otp",
			data,
		);
	};

	static triggerPabblyWebhook = (
		data: TriggerPabblyWebhooRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.customer + "/pabbly/webhook",
			data,
		);
	};
}

export default CustomerService;

export const listOfStates = [
	"Andhra Pradesh",
	"Assam",
	"Bihar",
	"Chhattisgarh",
	"Chandigarh",
	"Delhi",
	"Goa",
	"Gujarat",
	"Haryana",
	"Himachal Pradesh",
	"Jharkhand",
	"Karnataka",
	"Kerala",
	"Madhya Pradesh",
	"Maharashtra",
	"Odisha",
	"Puducherry",
	"Punjab",
	"Rajasthan",
	"Tamil Nadu",
	"Telengana",
	"Uttarakhand",
	"Uttar Pradesh",
	"West Bengal",
];

export const institutesList = {
	ALLEN: "ALLEN",
	AAKASH: "AAKASH",
	PHYSICSWALLAH: "PHYSICSWALLAH",
	UNACADEMY: "UNACADEMY",
	RESONANCE: "RESONANCE",
	MOTION: "MOTION",
	MITSDE: "MITSDE",
};

export const ApprovedAmount = {
	CC1: {
		ALLEN: "3,00,000",
		AAKASH: "3,00,000",
		UNACADEMY: "3,00,000",
		PHYSICSWALLAH: "1,50,000",
		RESONANCE: "1,50,000",
		MOTION: "1,50,000",
		MITSDE: "1,50,000",
	},
	NTC: {
		ALLEN: "2,00,000",
		AAKASH: "2,00,000",
		UNACADEMY: "2,00,000",
		PHYSICSWALLAH: "1,50,000",
		RESONANCE: "1,50,000",
		MOTION: "1,50,000",
		MITSDE: "1,50,000",
	},
};

export const stepsData = {
	ALLEN: [
		"Go to any Allen center  of your choice and talk to admission cousnellor",
		"Finalize the admission fees and ask for Propelld's No Cost EMI loan",
		"Fill the registration form for Allen and create your Form Number",
		"Generate your Challan from Allen (this is where Allen mentions your final fees)",
		"Go to Propelld helpdesk in the Allen Center and our agents will help complete your application",
	],
	AAKASH: [
		"Go to any Aakash center of your choice and talk to admission cousnellor",
		"Finalize the admission fees and ask for Propelld's No Cost EMI loan",
		"Counsellor will create Draft Summary for your Institute Application",
		"Go to Propelld helpdesk in the Aakash Center and our agents will help complete your application",
	],
	PHYSICSWALLAH: [
		"Go to any Physicwallah center of your choice and talk to admission cousnellor",
		"Finalize the admission fees and ask for Propelld's No Cost EMI loan",
		"Go to Propelld helpdesk in the Physicswallah Center and our agents will help complete your application",
	],
	UNACADEMY: [
		"Go to any Unacademy center of your choice and talk to admission cousnellor",
		"Finalize the admission fees and ask for Propelld's No Cost EMI loan",
		"Get the link generated for your Unacademy Application and ask him to forward it to Propelld",
		"Go to Propelld helpdesk in the Unacademy Center and our agents will help complete your application",
	],
	RESONANCE: [
		"Go to any Resonance center of your choice and talk to admission cousnellor",
		"Finalize the admission fees and ask for Propelld's No Cost EMI loan",
		"Go to Propelld helpdesk in the Resonance Center and our agents will help complete your application",
	],
	MOTION: [
		"Go to any Motion center of your choice and talk to admission cousnellor",
		"Finalize the admission fees and ask for Propelld's No Cost EMI loan",
		"Go to Propelld helpdesk in the Motion Center and our agents will help complete your application",
	],
	MITSDE: [
		"Go to MIT website or centre and talk to admission counsellor",
		"Finalize the admission fees and ask for Propelld's EMI/Loan Option",
		"Fill the registration form for MIT and create your Form Number",
		"Inform MIT team to generate loan Application/Form",
		"Check and fill all details and complete your loan application",
	],
};

export const getApprovedAmount = (institute: string, policy: string) => 
{
	const approvedDataAsPerPolicy =
        ApprovedAmount[policy as keyof typeof ApprovedAmount];
	if (!approvedDataAsPerPolicy) return "";

	return (
		approvedDataAsPerPolicy[
            institute as keyof typeof approvedDataAsPerPolicy
		] || ""
	);
};
